import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Card, CardActions, CardHeader, CardMedia,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItem,
    MenuItem,
    Select, Switch,
    TextField
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useEffect, useRef, useState} from "react";
import Api, {postApi} from "../Api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import JoditEditor from "jodit-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {
    AddBoxOutlined,
    ArrowDownwardOutlined,
    ArrowUpwardOutlined,
    DragHandle,
    FileUploadOutlined
} from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import {useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import * as React from "react";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const Constructor = () => {
    const [activeProject, setActiveProject] = useState('mfs')
    const [projects, setProjects] = useState([{shortname:'mfs',name:'Metrostroi FS'}])
    const [questions, setQuestions] = useState([])
    const [vac, setVac] = useState([])
    const [loading, setLoading] = useState(false)
    const [expanded, setExpanded] = useState(false);
    const [shownVacancy, setShownVacancy] = useState({})
    const [eName, setEName] = useState('')
    const [visible, setVisible] = useState(0)
    const [addOpen, setAddOpen] = useState(false)
    const [newVacName, setNewVacName] = useState('')
    const [minMax, setMinMax] = useState([0,0])
    const permissions = useSelector(state=>state.permissions.value)
    const editor = useRef();
    const config = {
        readonly: false,
        theme: 'dark',
        enter: 'BR',
        toolbar: false,
        inline: true,
        toolbarInlineForSelection: true
    }
    const getProjects = async () => {
        setProjects(await Api('hr/project/get-all-project'))
    }
    const getQuestions = async () => {
        setQuestions(await Api('hr/question/get-questions'))
    }
    const getVacancies = async () => {
        setLoading(true)
        await setVac(await Api(`hr/vacancy/get-all-vacancies-project/${activeProject}`))
        setLoading(false)
    }

    const handleAccordionPanel = (panel, item) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setShownVacancy(item)
        setEName(item.name)
        setVisible(item.visible)
    };

    useEffect(()=>{
        getProjects()
        getQuestions()
        //eslint-disable-next-line
    },[]);

    useEffect(()=>{
        setExpanded(false)
        getVacancies();
        //eslint-disable-next-line
    },[activeProject])
    useEffect(() => {
        let min = 0;
        let max = 0;
        for(let v of vac){
            if(v.order < min){min = v.order}
            if(v.order > max){max = v.order}
        }
        setMinMax([min,max]);
        //eslint-disable-next-line
    },[vac])
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        marginTop: 2,
        flexDirection: 'row',
        // change background colour if dragging
        background: isDragging ? "rgba(38,50,56,0.79)" : "#263238",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "rgba(38,50,56,0.62)" : "inherit",
        maxHeight: '100vh',
        overflowY: 'auto'
    });

    const resolveQuestion = i => {
        for(let q of questions){ if(q.id === i) return q}
    }
    const resolveType = q => {
        return {1: 'Строка свободного ввода',
                2: 'Большое поле свободного ввода',
                3: 'Список вариантов:'+q.expected,
                4: 'Список вариантов (Да/Нет)',
                5: 'Строка-разделитель'
        }[q.type]
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const insert = (list, startIndex, id) => {
        const result = Array.from(list);
        const nqid = questions.filter((i)=>list.indexOf(i.id) < 0)[id].id;
        result.splice(startIndex, 0, nqid);
        return result;
    }

    const handleImageUpload = e => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = (r) => {
            let v = structuredClone(vac);
            for(let vc of v) {
                if (vc.id === shownVacancy.id) {
                    vc.photo = r.target.result.split("base64,")[1];
                }
            }
            setVac(v);
        };
        reader.readAsDataURL(file);
    }
    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        let v = structuredClone(vac);
        for(let vc of v) {
            if (vc.id === shownVacancy.id) {
                vc.question = JSON.parse(vc.question)
                //Обработка перебрасывания вопроса из банка вопросов:
                if(result.source.droppableId === 'questionbank' && result.destination.droppableId === 'questions'){
                    vc.question = insert(vc.question,result.destination.index,result.source.index)
                }
                //Обработка перебрасывания вопроса в банк
                if(result.source.droppableId === 'questions' && result.destination.droppableId === 'questionbank'){
                    vc.question.splice(result.source.index,1)
                }
                //Сортировка внутри вопросов вакансии
                if(result.source.droppableId === 'questions' && result.destination.droppableId === 'questions'){
                    vc.question = reorder(vc.question,result.source.index, result.destination.index)
                }
                vc.question = JSON.stringify(vc.question)
                setShownVacancy(vc);
            }
        }
        setVac(v);

    }
    const saveCV = item => {
        item.name = eName;
        item.visible = visible;
        postApi(`hr/vacancy/update-vacancy`, item).then(()=>{getVacancies()});
    }
    const changeVacField = (item, field) => e => {
        item[field] = e;
    }
    const ReorderVac = (item, direction) => e => {
        e.stopPropagation();
        let candidate = {id: -1}
        if(direction === "up"){
            candidate['order'] =  minMax[0];
            for(let v of vac){
                if(v.order >= candidate.order && v.order < item.order ){
                    candidate = v;
                }
            }
        }
        if(direction === 'down'){
            candidate['order'] =  minMax[1];
            for(let v of vac){
                if(v.order <= candidate.order && v.order > item.order ){
                    candidate = v;
                }
            }
        }
        if(candidate.id > -1){
            postApi(`hr/vacancy/update-order`,{id:candidate.id, order:item.order}).then(()=>{
                postApi(`hr/vacancy/update-order`,{id:item.id, order: candidate.order}).then(()=>{
                    getVacancies();
                })
            })
        }
    }
    const addVacancy = () => {
        let order = 0;
        for(let t of vac){ order = t.order + 1}
        let v = {
            "order": order,
            "project": activeProject,
            "name": newVacName,
            "type": 0,
            "delete_is": 1,
            "description": "",
            "requirements": "",
            "conditions": "",
            "question": "[]",
            "status": "[]",
            "photo": "",
            "visible": 0
        }
        postApi(`hr/vacancy/new-vacancy`,v).then(()=>{getVacancies()})
    }
    return <div>
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                    <Typography variant={'h5'} sx={{p:2}}>Конструктор вакансий</Typography>
                </Box>
                {permissions.indexOf('edit_vacancy') > -1 &&
                <Box sx={{ flexGrow: 10, p:2 }}>
                    <Select size={'small'}
                            value={activeProject}
                            onChange={(e)=>{setActiveProject(e.target.value)}}
                            sx={{width: '400px'}}
                    >
                        {projects.map((i,e)=><MenuItem key={'proj_'+e} value={i.shortname}>{i.name}</MenuItem>)}
                    </Select>
                </Box>
                }
                {permissions.indexOf('edit_vacancy') > -1 &&
                    <Box sx={{ flexGrow: 1, p:2 }}>
                        <IconButton color={'success'} onClick={()=>{setAddOpen(true)}} title={'Добавить вакансию'}>
                            <AddBoxOutlined/>
                        </IconButton>
                    </Box>
                }
            </Box>
        </Paper>
        {permissions.indexOf('edit_vacancy') > -1 &&
        <Paper sx={{ width: '100%', mt:1, backgroundColor: 'rgba(255, 255, 255, 0.12)'}}>
            {loading && <div>
                <CircularProgress /> Загружается список вакансий....
            </div>}
            {!loading && <Box>
                {vac.map((i,e)=>{
                    return <Accordion expanded={expanded === `panel${e}`}
                               onChange={handleAccordionPanel(`panel${e}`, i)}
                               key={`vac_${e}`}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <IconButton size={'small'} title={'Поднять выше'} disabled={i.order === minMax[0]} onClick={ReorderVac(i, 'up')}><ArrowUpwardOutlined/></IconButton>
                            <IconButton size={'small'} title={'Отпустить ниже'} disabled={i.order === minMax[1]} onClick={ReorderVac(i, 'down')}><ArrowDownwardOutlined/></IconButton>
                            <Typography>{i.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {expanded === `panel${e}` &&
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <TextField label={'Название вакансии'} value={eName} fullWidth onChange={(e)=>{setEName(e.target.value)}}/>
                                </Grid>
                                <Grid item xs={4}>
                                    Видимость: <Switch checked={visible===1} onChange={()=>{setVisible(visible?0:1)}}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card>
                                        <CardHeader titleTypographyProps={{variant: 'body'}} title={'Фото'}/>
                                        <CardMedia component={"img"} sx={{width: '100%'}}
                                                   alt={'Фото вакансии'}
                                                   src={`data:image/png;base64,${i.photo}`}/>
                                        <CardActions>
                                            <Button component="label" variant="contained" startIcon={<FileUploadOutlined />}>
                                                Загрузить фото
                                                <VisuallyHiddenInput type="file" onChange={handleImageUpload}/>
                                            </Button>
                                        </CardActions>
                                    </Card>
                                    <Box sx={{p:2, outline: 1}}>
                                        <Typography variant={'subtitle1'}>Описание вакансии</Typography>
                                        <JoditEditor  config={config} ref={editor} value={i.description} onChange={changeVacField(i,'description')}/>
                                    </Box>
                                    <Box sx={{p:2, outline: 1}}>
                                        <Typography variant={'subtitle1'}>Требования</Typography>
                                        <JoditEditor  config={config} ref={editor} value={i.requirements} onChange={changeVacField(i,'requirements')}/>
                                    </Box>
                                    <Box sx={{p:2, outline: 1}}>
                                        <Typography variant={'subtitle1'}>Условия</Typography>
                                        <JoditEditor  config={config} ref={editor} value={i.conditions} onChange={changeVacField(i,'conditions')}/>
                                    </Box>
                                </Grid>
                                <DragDropContext onDragEnd={onDragEnd}>
                                <Grid item xs={4}>
                                    Вопросы к анкете:
                                        <Droppable droppableId="questions">
                                            {(provided, snapshot) => (
                                                <List
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {JSON.parse(i.question).map((i,e)=>{
                                                        let question = resolveQuestion(i);
                                                        return <Draggable key={'q_'+e} draggableId={`question_${e}`}  index={e}>
                                                            {(provided, snapshot)=>(
                                                                <ListItem ref={provided.innerRef}
                                                                     {...provided.draggableProps}
                                                                     {...provided.dragHandleProps}
                                                                     style={getItemStyle(
                                                                         snapshot.isDragging,
                                                                         provided.draggableProps.style
                                                                     )}
                                                                          divider
                                                                >
                                                                    <ListItemIcon>
                                                                        <DragHandle />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={question.text} secondary={resolveType(question)}/>
                                                                </ListItem>
                                                            )}
                                                        </Draggable>})}
                                                    {provided.placeholder}
                                                </List>
                                            )}
                                        </Droppable>
                                </Grid>
                                <Grid item xs={4}>
                                    Банк вопросов:
                                        <Droppable droppableId="questionbank">
                                            {(provided, snapshot) => (
                                                <List
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {questions.filter((i)=>JSON.parse(shownVacancy.question).indexOf(i.id) === -1).map((question,e)=>{
                                                        return <Draggable key={'qb_'+e} draggableId={`question_b${e}`}  index={e}>
                                                            {(provided, snapshot)=>(
                                                                <ListItem ref={provided.innerRef}
                                                                          {...provided.draggableProps}
                                                                          {...provided.dragHandleProps}
                                                                          style={getItemStyle(
                                                                              snapshot.isDragging,
                                                                              provided.draggableProps.style
                                                                          )}
                                                                          divider
                                                                >
                                                                    <ListItemIcon>
                                                                        <DragHandle />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={question.text} secondary={resolveType(question)}/>
                                                                </ListItem>
                                                            )}
                                                        </Draggable>})}
                                                    {provided.placeholder}
                                                </List>
                                            )}
                                        </Droppable>
                                </Grid>
                                </DragDropContext>
                            </Grid>
                            }
                            <Button variant={'contained'} color={'success'} onClick={()=>{saveCV(i)}}>СОХРАНИТЬ</Button>
                        </AccordionDetails>
                    </Accordion>
                })}
            </Box>}
            <Dialog
                open={addOpen}
                onClose={()=>{setAddOpen(false)}}
                aria-labelledby="add-dialog-title"
                aria-describedby="add-dialog-description"
            >
                <DialogTitle id="add-dialog-title">
                    {"Добавление новой вакансии"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component={'span'} id="comment-dialog-description">
                        <TextField fullWidth value={newVacName} placeholder="Введите название вакансии"
                                   onChange={(e)=>{setNewVacName(e.target.value)}}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setAddOpen(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                    <Button onClick={addVacancy} color={'success'} variant={'contained'}>Добавить</Button>
                </DialogActions>
            </Dialog>
        </Paper>
        }
        {permissions.indexOf('edit_vacancy') === -1 &&
            <Paper>
                <Typography color={'error'}>У вас нет права на использование этого инструмента</Typography>
            </Paper>
        }
    </div>
}
export default Constructor;