import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import * as React from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import {useSelector} from "react-redux";
import TableBody from "@mui/material/TableBody";
import {styled} from "@mui/material/styles";
import {useEffect, useState} from "react";
import Api from "../Api";
import {isMobile} from "react-device-detect";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const StyledTableCell = styled(TableCell)(({theme}) => ({
    root: {
        border: "1px solid #7f7f7f",
    },
}));

const Rules = () => {
    const [data, setData] = useState([])
    const project = useSelector(state => state.session.project);
    const getData = async () => {
        setData(await Api(`admin/rules/${project}`))
    }
    //eslint-disable-next-line
    useEffect(()=>{getData()},[project]);

    const SeverityHumanReadable = seconds => {
        let sy=365*24*3600
        let sm=30*24*3600
        let sd=86400
        let sh=3600
        let years = Math.floor(seconds/sy)
        let months = Math.floor((seconds - years*sy)/sm)
        let days = Math.floor((seconds - years*sy - months*sm)/sd)
        let hours = Math.floor((seconds - years*sy - months*sm - days*sd)/sh)
        let minutes = Math.floor((seconds - years*sy - months*sm - days*sd - hours*sh)/60)
        let sec = Math.floor(seconds - years*sy - months*sm - days*sd - hours*sh - minutes*60)
        return years+"л, "+months+"м., "+days+"д., "+hours+"ч., "+minutes+"м., "+sec+"с."
    }
    const RuleCompare = (a,b) => {
        let r1 = a.RuleName;
        let r2 = b.RuleName;
        if(r1.indexOf("[") > -1 && r1.indexOf("]") > -1) r1 = r1.replace("[","").replace("]","") + ".0"
        if(r2.indexOf("[") > -1 && r2.indexOf("]") > -1) r2 = r2.replace("[","").replace("]","") + ".0"
        return (parseInt(r1.split(".")[0])*100+parseInt(r1.split(".")[1]))
            - (parseInt(r2.split(".")[0])*100+parseInt(r2.split(".")[1]))
    }

    const rulesTable = () => {
        data.sort(RuleCompare)
        return data.map((ii,ee)=>
            <StyledTableRow key={'rule_cat_'+ee}>
                <StyledTableCell>{ii.RuleName}</StyledTableCell>
                {project !== "discord" && <StyledTableCell>{ii.FullRuleName}</StyledTableCell>}
                <StyledTableCell>{ii.RuleDescription}</StyledTableCell>
                {project !== "discord" && <StyledTableCell>{SeverityHumanReadable(ii.Severity)}</StyledTableCell>}
                {project === "discord" && <StyledTableCell>{ii.Punishment}</StyledTableCell>}
            </StyledTableRow>)
    }

    return <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Typography variant={'h5'} sx={{p:2}}>Правила направления {project.toUpperCase()}</Typography>
        <TableContainer sx={{ maxHeight: 'calc(90vh - 80px)'}}>
            <Table stickyHeader aria-label="sticky table" size={"small"}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>{isMobile ? "#" : "Номер правила"}</StyledTableCell>
                        <StyledTableCell>Наименование</StyledTableCell>
                        {!isMobile && project !== "discord" && <StyledTableCell>Полное описание</StyledTableCell>}
                        {project !== "discord" && <StyledTableCell>Уровень опасности</StyledTableCell>}
                        {project === "discord" && <StyledTableCell>Наказание</StyledTableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rulesTable()}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}
export default Rules