import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {styled} from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import {AddBoxOutlined, DeleteOutlined, BlockOutlined, ThumbUpAltOutlined} from "@mui/icons-material";
import Api, {postApi} from "../Api";
import {
    Link,
    MenuItem,
    Select,
    Switch,
    TextField,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent, DialogContentText, DialogActions
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import moment from "moment";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Profile = () => {
    const { steamid } = useParams();
    const [data, setData] = useState({})
    const [ranks, setRanks] = useState([])
    const [reprovals, setReprovals] = useState([])
    const [vacations, setVacations] = useState([])
    const [cacomments, setCaComments] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [removeReason, setRemoveReason] = useState('');
    const [cOpen, setCopen] = useState(false)
    const [newComment, setNewComment] = useState('')
    const [vOpen, setVopen] = useState(false)
    const [vacType, setVacType] = useState("Основной отпуск")
    const [vacStart, setVacStart] = useState(moment().format('YYYY-MM-DD'))
    const [vacEnd, setVacEnd] = useState(moment().format('YYYY-MM-DD'))
    const [rOpen, setRopen] = useState(false)
    const [repReason, setRepReason] = useState('')
    const project = useSelector(state => state.session.project);
    const permissions = useSelector(state => state.permissions.value);
    const navigate = useNavigate()
    const getData = async () => {
        setEditMode(false)
        setData(await Api(`admin/staff/get-data/${steamid}/${project}`))
        setRanks(await Api(`admin/staff/ranks/${project}`))
        setReprovals(await Api(`admin/staff/get-reprovals/${steamid}/${project}`))
        setVacations(await Api(`admin/staff/get-vacations/${steamid}/${project}`))
        if(permissions.indexOf('view_ca_comments') > -1) {
            setCaComments(await Api(`admin/staff/get-ca-comments/${steamid}/${project}`))
        }
    }
    const saveProfile = () => {
        postApi(`admin/staff/update-profile/${steamid}/${project}`,data).then((res)=>{getData()});
    }
    const removeAdmin =() => {
        postApi(`admin/staff/remove-admin/${steamid}/${project}`,{reason: removeReason}).then((res)=>{
            navigate('/staff')
        });
    }
    const addCaComment = () => {
        postApi(`admin/staff/add-ca-comment/${steamid}/${project}`,{comment: newComment}).then(()=>{
            setCopen(false);
            getData();
        });
    }
    const addVacation = () => {
        postApi(`admin/staff/add-vacation/${steamid}/${project}`,{type: vacType, start: vacStart, end: vacEnd}).then(()=>{
            setVopen(false);
            getData();
        });
    }
    const addReproval = () => {
        postApi(`admin/staff/add-reproval/${steamid}/${project}`,{reason: repReason}).then(()=>{
            setRopen(false);
            getData();
        });
    }
    const deleteCaComment = id => {
        postApi(`admin/staff/delete-ca-comment`,{id: id}).then(()=>{
            getData();
        });
    }
    const stopVacation = id => {
        postApi(`admin/staff/stop-vacation`,{id: id}).then(()=>{
            getData();
        });
    }
    const declineReproval = id => {
        postApi(`admin/staff/revoke-reproval`,{id: id}).then(()=>{
            getData();
        });
    }
    const renewAdmin = () => {
        postApi(`admin/staff/renew-admin`, {id: data.ID}).then(()=>{
            getData();
        })
    }
    const resolveRank = id => {
        let r = ranks.filter(i=>i.RankID === id)
        if(r[0])
            return r[0].Name
        return "N/A"
    }
    const getTgLink = profile => {
        if(!profile) return "";
        if(profile.indexOf('https') > -1){
            return profile;
        }
        return 'https://t.me/'+profile.replace('@','');
    }
    //eslint-disable-next-line
    useEffect(()=>{getData();}, [project, permissions]);
    return <div style={{ width: '100%', overflow: 'hidden' }}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <Typography variant={'h5'} sx={{p:2}} align={'center'}>
                        Профиль {!data.active && 'бывшего'} администратора <span style={{'color':'#33ff33'}}>
                            {data.Name}</span> в проекте {project.toUpperCase()}
                            {permissions.indexOf('edit_profile') > -1 && <Switch checked={editMode} onChange={()=>{setEditMode(!editMode)}}></Switch>}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <Typography variant={'h6'} sx={{p:2}} align={'center'}>Установочные данные</Typography>
                            <TableContainer sx={{ p:2 }}>
                                <Table aria-label="sticky table" size={"small"}>
                                    <TableBody>
                                        <StyledTableRow>
                                            <TableCell>Никнейм:</TableCell>
                                            <TableCell>{data.Name}</TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>Должность:</TableCell>
                                            <TableCell>{(!editMode || !data.active) ?
                                                resolveRank(data.Rank) :
                                                <FormControl fullWidth>
                                                    <Select
                                                        value={data.Rank}
                                                        onChange={(e)=>{
                                                            let clone = structuredClone(data)
                                                            clone.Rank = e.target.value;
                                                            setData(clone)
                                                        }}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        size={'small'}
                                                    >
                                                        {ranks.map((i,e)=><MenuItem value={i.RankID} key={`ranksel_item_${e}`}>{i.Name}</MenuItem>)}
                                                    </Select>
                                                </FormControl> }</TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>Специфика:</TableCell>
                                            <TableCell>{(!editMode || !data.active) ? data.description :
                                                <TextField fullWidth size={'small'} value={data.description}
                                                           onChange={(e)=>{
                                                               let clone = structuredClone(data);
                                                               clone.description = e.target.value;
                                                               setData(clone);
                                                           }
                                                }
                                                />
                                            }</TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>Реальное имя:</TableCell>
                                            <TableCell>{(!editMode || !data.active) ? data.real_name :
                                                <TextField fullWidth size={'small'} value={data.real_name}
                                                    onChange={(e)=>{
                                                        let clone = structuredClone(data);
                                                        clone.real_name = e.target.value;
                                                        setData(clone);
                                                    }}
                                                />
                                            }</TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>Профиль ВК:</TableCell>
                                            <TableCell>{(!editMode || !data.active) ?
                                                <Link sx={{textDecoration:'none'}}
                                                             href={data.vk_id}
                                                             target={'_blank'}
                                                        >{data.vk_id}</Link> :
                                                <TextField fullWidth size={'small'} value={data.vk_id}
                                                           onChange={(e)=>{
                                                               let clone = structuredClone(data);
                                                               clone.vk_id = e.target.value;
                                                               setData(clone);
                                                           }}
                                                />
                                                        }
                                            </TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>Профиль в форуме:</TableCell>
                                            <TableCell>{(!editMode || !data.active) ?
                                                <Link sx={{textDecoration: 'none'}}
                                                             href={data.forum_profile}
                                                             target={'_blank'}
                                                >{data.forum_profile}</Link>:
                                                <TextField fullWidth size={'small'} value={data.forum_profile}
                                                           onChange={(e)=>{
                                                               let clone = structuredClone(data);
                                                               clone.forum_profile = e.target.value;
                                                               setData(clone);
                                                           }}
                                                />
                                                        }
                                            </TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>Профиль в телеграм:</TableCell>
                                            <TableCell>{(!editMode || !data.active) ?
                                                <Link sx={{textDecoration: 'none'}}
                                                      href={getTgLink(data.telegram_profile)}
                                                      target={'_blank'}
                                                >{data.telegram_profile}</Link>:
                                                <TextField fullWidth size={'small'} value={data.telegram_profile}
                                                           onChange={(e)=>{
                                                               let clone = structuredClone(data);
                                                               clone.telegram_profile = e.target.value;
                                                               setData(clone);
                                                           }}
                                                />
                                            }
                                            </TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>DISCORD ID:</TableCell>
                                            <TableCell>{(!editMode || !data.active)? data.discord_id:
                                                <TextField fullWidth size={'small'} value={data.discord_id}
                                                           onChange={(e)=>{
                                                               let clone = structuredClone(data);
                                                               clone.discord_id = e.target.value;
                                                               setData(clone);
                                                           }}
                                                />
                                            }</TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>Очередное повышение:</TableCell>
                                            <TableCell>N/A</TableCell>
                                        </StyledTableRow>
                                        {editMode && permissions.indexOf('edit_profile') > -1 &&
                                        <StyledTableRow>
                                            <TableCell>
                                                {data.active===1 &&
                                                <Button variant={'outlined'} color={'success'}
                                                        onClick={saveProfile}>СОХРАНИТЬ</Button>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {(permissions.indexOf('remove_admin') > -1 && data.active===1) &&
                                                    <Button variant={'contained'} color={'error'}
                                                            onClick={()=>{setDialogOpen(true)}}>СНЯТЬ</Button>
                                                }
                                                {(permissions.indexOf('remove_admin') > -1 && data.active===0) &&
                                                    <Button variant={'contained'} color={'success'}
                                                            onClick={renewAdmin}>ВОССТАНОВИТЬ</Button>
                                                }
                                            </TableCell>
                                        </StyledTableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    {permissions.indexOf('view_ca_comments') > -1 && <Grid item xs={12}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <Typography variant={'h6'} sx={{p:2}} align={'center'}>Комментарии главной администрации
                                {permissions.indexOf('add_ca_comments') > -1 && data.active===1 &&
                                <IconButton onClick={()=>{setCopen(true)}}>
                                    <AddBoxOutlined/>
                                </IconButton>
                                }
                            </Typography>
                            <TableContainer sx={{ p:2 }}>
                                <Table stickyHeader aria-label="sticky table" size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Автор</TableCell>
                                            <TableCell>Комментарий</TableCell>
                                            {permissions.indexOf('add_ca_comments') > -1 &&
                                                <TableCell></TableCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cacomments.length > 0 && cacomments.map((i,e)=><StyledTableRow key={`rep_${e}`}>
                                            <TableCell>{i.boss_name.Name}</TableCell>
                                            <TableCell>{i.comment}</TableCell>
                                            {permissions.indexOf('add_ca_comments') > -1 && data.active===1 &&
                                                <TableCell align={'right'}>
                                                    <IconButton
                                                        title={'Удалить комментарий'}
                                                        onClick={()=>{deleteCaComment(i.id)}}
                                                    ><DeleteOutlined/></IconButton>
                                                </TableCell>
                                            }
                                        </StyledTableRow>)}
                                        {!cacomments.length && <StyledTableRow>
                                            <TableCell colSpan={2} align={'center'}>Нет комментариев</TableCell>
                                            {permissions.indexOf('add_ca_comments') > -1 && data.active===1 &&
                                                <TableCell align={'right'}></TableCell>
                                            }
                                        </StyledTableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>}
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <Typography variant={'h6'} sx={{p:2}} align={'center'}>Отпуски сотрудника
                                {permissions.indexOf('manage_vacations') > -1 && data.active===1 &&
                                <IconButton onClick={()=>{setVopen(true)}}>
                                    <AddBoxOutlined/>
                                </IconButton>
                                }
                            </Typography>
                            <TableContainer sx={{ p:2 }}>
                                <Table stickyHeader aria-label="sticky table" size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Тип отпуска</TableCell>
                                            <TableCell>Дата начала</TableCell>
                                            <TableCell>Дата окончания</TableCell>
                                            {permissions.indexOf('manage_vacations') > -1 && data.active===1 &&
                                                <TableCell></TableCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {vacations.length > 0 && vacations.map((i,e)=><StyledTableRow key={`rep_${e}`}>
                                            <TableCell>{i.vac_type}</TableCell>
                                            <TableCell>{moment(i.from_date).format('DD.MM.YYYY')}</TableCell>
                                            <TableCell>{moment(i.to_date).format('DD.MM.YYYY')}</TableCell>
                                            {permissions.indexOf('manage_vacations') > -1 && data.active===1 &&
                                                <TableCell align={'right'} onClick={()=>{stopVacation(i.id)}}>
                                                    {moment(i.to_date) >= moment() &&
                                                        <IconButton title={'Отменить отпуск'}><BlockOutlined/></IconButton>
                                                    }
                                                </TableCell>
                                            }
                                        </StyledTableRow>)}
                                        {!vacations.length && <StyledTableRow>
                                            <TableCell colSpan={3} align={'center'}>Нет отпусков</TableCell>
                                        </StyledTableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <Typography variant={'h6'} sx={{p:2}} align={'center'}>Выговоры сотрудника
                                {permissions.indexOf('manage_reprovals') > -1 && data.active===1 &&
                                <IconButton onClick={()=>{setRopen(true)}}>
                                    <AddBoxOutlined/>
                                </IconButton>
                                }
                            </Typography>
                            <TableContainer sx={{ p:2 }}>
                                <Table stickyHeader aria-label="sticky table" size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Дата</TableCell>
                                            <TableCell>Кто выдал</TableCell>
                                            <TableCell>Причина</TableCell>
                                            <TableCell>Статус</TableCell>
                                            {permissions.indexOf('manage_reprovals') > -1 && data.active===1 &&
                                                <TableCell></TableCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reprovals.length > 0 && reprovals.map((i,e)=><StyledTableRow key={`rep_${e}`}>
                                            <TableCell>{moment(i.created_date).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
                                            <TableCell>{i.boss.Name}</TableCell>
                                            <TableCell>{i.description}</TableCell>
                                            <TableCell>{i.status ? 'Активен' : 'Не активен'}</TableCell>
                                            {permissions.indexOf('manage_reprovals') > -1 && data.active===1 &&
                                                <TableCell align={'right'}>
                                                    {i.status === 1 &&
                                                    <IconButton title={'Снять выговор'} onClick={()=>{declineReproval(i.id)}}>
                                                        <ThumbUpAltOutlined/>
                                                    </IconButton>
                                                    }
                                                </TableCell>
                                            }
                                        </StyledTableRow>)}
                                        {!reprovals.length && <StyledTableRow>
                                            <TableCell colSpan={4} align={'center'}>Нет выговоров</TableCell>
                                        </StyledTableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {permissions.indexOf('remove_admin') > -1 &&
            <Dialog
                open={dialogOpen}
                onClose={()=>{setDialogOpen(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Снятие администратора"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component={'span'} id="alert-dialog-description">
                        Вы собираетесь <span style={{color:'#ff6666'}}>снять</span> администратора {data.Name} в проекте {project.toUpperCase()}!<br/>
                        <TextField fullWidth value={removeReason} placeholder="Причина для снятия" onChange={(e)=>{setRemoveReason(e.target.value)}}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setDialogOpen(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                    <Button onClick={removeAdmin} color={'error'} variant={'contained'}>
                        Да, я хочу его снять!
                    </Button>
                </DialogActions>
            </Dialog>
        }
        {permissions.indexOf('add_ca_comments') > -1 &&
            <Dialog
                open={cOpen}
                onClose={()=>{setCopen(false)}}
                aria-labelledby="comment-dialog-title"
                aria-describedby="comment-dialog-description"
            >
                <DialogTitle id="comment-dialog-title">
                    {"Добавление комментария ГА/ЗГА"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component={'span'} id="comment-dialog-description">
                        <TextField fullWidth value={newComment} placeholder="Введите комментарий"
                                   onChange={(e)=>{setNewComment(e.target.value)}}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setCopen(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                    <Button onClick={addCaComment} color={'success'} variant={'contained'}>Добавить</Button>
                </DialogActions>
            </Dialog>
        }
        {permissions.indexOf('manage_vacations') > -1 &&
            <Dialog
                open={vOpen}
                onClose={()=>{setVopen(false)}}
                aria-labelledby="vacations-dialog-title"
                aria-describedby="vacations-dialog-description"
            >
                <DialogTitle id="vacations-dialog-title">
                    {"Добавление нового отпуска"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component={'span'} id="vacations-dialog-description">
                        <TextField fullWidth label={'Тип отпуска'} value={vacType} placeholder="Тип отпуска"
                                   onChange={(e)=>{setVacType(e.target.value)}} sx={{mt:2}}/>
                        <TextField fullWidth label={'Начало отпуска'} type={'date'} value={vacStart} placeholder={'Начало отпуска'}
                                   onChange={(e)=>{setVacStart(e.target.value)}} sx={{mt:2}}/>
                        <TextField fullWidth label={'Конец отпуска'} type={'date'} value={vacEnd} placeholder={'Конец отпуска'}
                                   onChange={(e)=>{setVacEnd(e.target.value)}} sx={{mt:2}}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setVopen(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                    <Button onClick={addVacation} color={'success'} variant={'contained'}>Добавить</Button>
                </DialogActions>
            </Dialog>
        }
        {permissions.indexOf('manage_reprovals') > -1 &&
            <Dialog
                open={rOpen}
                onClose={()=>{setRopen(false)}}
                aria-labelledby="reprovals-dialog-title"
                aria-describedby="reprovals-dialog-description"
            >
                <DialogTitle id="reprovals-dialog-title">
                    {"Добавление нового выговора"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component={'span'} id="reprovals-dialog-description">
                        <TextField fullWidth label={'Причина'} value={repReason} placeholder="Причина выговора"
                                   onChange={(e)=>{setRepReason(e.target.value)}} sx={{mt:2}}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setRopen(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                    <Button onClick={addReproval} color={'success'} variant={'contained'}>Добавить</Button>
                </DialogActions>
            </Dialog>
        }
    </div>
}
export default Profile;