import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LogoMFS from '../images/mfs_logo.png';
import LogoTFS from '../images/tfs_logo.png';
import LogoPH from '../images/prophunt_logo.png';
const steamauthurl = "https://steamcommunity.com/openid/login"+
    "?openid.ns=http://specs.openid.net/auth/2.0"+
    "&openid.mode=checkid_setup&openid.return_to="+
    window.location.origin + "/auth/"+
    "&openid.realm=" + window.location.origin+
    "&openid.identity=http://specs.openid.net/auth/2.0/identifier_select"+
    "&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select";

const LoginPage = () => (
    <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: 'calc(100vh - 200px)' }}
    >
        <Grid item xs={4}>
            <img src={LogoMFS} style={{height: '20vh'}} alt={"Logo"}/>
            <img src={LogoTFS} style={{height: '20vh'}} alt={"Logo"}/>
            <img src={LogoPH} style={{height: '19vh', position: 'relative', top:'-0.7vh'}} alt={"Logo"}/>
        </Grid>
        <Grid item xs={4}>
            <h3>Вход в административный портал</h3>
        </Grid>
        <Grid item xs={4}>
            <Button href={steamauthurl} variant={"contained"} color={"primary"}>Войти через STEAM</Button>
        </Grid>

    </Grid>
)
export default LoginPage